





























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Portal extends Vue {
  public baseUrl = process.env.BASE_URL;
}
